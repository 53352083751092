import React, { useEffect } from 'react'
import { Colors } from 'styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard } from '@fortawesome/free-regular-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { BancardIframe } from 'react-bancard-checkout-js'
import './BancardModal.css';

const BancardModal = (props) => {
	const { onHide, onConfirm, bancardProcessId, executeActionOnInit = null } = props

	useEffect(() => {
		if (typeof executeActionOnInit === 'function') {
			executeActionOnInit()
		}
	}, [])
	
	const handler = (bancardResponse) => {
		onConfirm(bancardResponse)
	}

	return (
		<div className="w3-modal w3-show">
			<div className="w3-modal-content w3-animate-zoom w3-round w3-round-large" style={{ width: 530 }}>
				<div className="w3-padding w3-light-gray w3-round w3-round-large bs-bbox">
					<span onClick={() => onHide()} className="w3-btn w3-light-gray w3-hover-theme w3-display-topright w3-round w3-round-large"><FontAwesomeIcon icon={faTimes} /></span>
					<span><FontAwesomeIcon icon={faCreditCard} color={Colors.THEME} /> Bancard</span>
				</div>
				<div className="w3-container w3-padding bs-bbox">
					{/*<BancardIframe processId={bancardProcessId} options={{handler: handleBancardResponse}} enviroment="Production" />*/}
					<BancardIframe 
						processId={bancardProcessId}
						options={{handler}}
					/>
				</div>
			</div>
		</div>
	)
}

export default BancardModal
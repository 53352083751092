const production = true;
const ID = 'tiendoline';
const APP_NAME = 'Tienda online';
const GOOGLE_MAP_KEY = 'AIzaSyCaw8ahy7ztFKt6aq9kbqVlM1Bk_YmUU9s';
const GOOGLE_RECAPTCHA_SITEKEY = '6LdGty4UAAAAACrpeTUV7sQGFl5KbGMjJHpLuOrE';
const DEVELOPMENT = {
    PRODUCTION: production,
    ID: ID,
    APP_NAME: APP_NAME,
    HOST: 'http://192.168.100.10/tiendoline/web',//localhost
    BASENAME: 'tiendoline/web/',//subdirectorio del host
    URL_BASE: 'http://192.168.100.10/tiendoline/server/',//api
    GOOGLE_MAP_KEY: GOOGLE_MAP_KEY,
    GOOGLE_RECAPTCHA_SITEKEY: GOOGLE_RECAPTCHA_SITEKEY,
    LOGS: true
}
const PRODUCTION = {
    PRODUCTION: production,
    ID: ID,
    APP_NAME: APP_NAME,
    HOST: 'https://tiendoline.carlsteingroup.com',//production (copiar y pegar en package.json --> homepage)
    BASENAME: '/',//subdirectorio
    URL_BASE: 'https://api.ingalca.com/tiendoline/',//api
    GOOGLE_MAP_KEY: GOOGLE_MAP_KEY,
    GOOGLE_RECAPTCHA_SITEKEY: GOOGLE_RECAPTCHA_SITEKEY,
    LOGS: false
}
export default (production) ? PRODUCTION : DEVELOPMENT;
import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { Helper } from 'utils';
import { Api } from 'services';
import CONFIG from "config/environment";
import { AppActions } from 'store';
import { DispatchContext } from 'context';
// import { StateContext, DispatchContext } from 'context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faHandHoldingUsd, faTruck, faMobileAlt, faSquare } from '@fortawesome/free-solid-svg-icons';
import { GridResponsiveCategorias, GridResponsiveProductos } from 'components/grid-productos';

//------------------------------------------------------------------------------------------------------
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import './inicio.css';

import { useWindowSize } from 'hooks';

import { useSnackbar } from 'notistack';
import SupportBubble from 'components/SupportBubble';

const Inicio = props => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useContext(DispatchContext);
    // const state = useContext(StateContext);
    const windowSize = useWindowSize();
    const [categorias1, setCategorias1] = useState([]);
	const [productosDestacados, setProductosDestacados] = useState([]);
    // const [categorias2, setCategorias2] = useState([]);
    // const [categorias3, setCategorias3] = useState([]);
    // const [configuraciones, setConfiguraciones] = useState(null);
    const [contenido, setContenido] = useState(null);
    const [sliderPortada, setSliderPortada] = useState(null);
    const [sliderCategoria, setSliderCategoria] = useState(null);
    const [imagenesListas, setImagenesListas] = useState(false);
	const [numeroWhatsApp, setNumeroWhatsApp] = useState(null);
    useEffect(() => {
        dispatch(AppActions.setLoading(false));
        dispatch(AppActions.setActionsDisabled(false));
        document.title = CONFIG.APP_NAME;
        // setProductosDestacados(productos);
        getConfig();
        getContenidoSeccion();
    }, []);
    useEffect(() => {
        if (contenido) {
            if (contenido.inicio && contenido.inicio.length > 0) {
                let imagenes = [];
                if (contenido.inicio[0] && contenido.inicio[0].partes && contenido.inicio[0].partes.length > 0) {
                    if (contenido.inicio[0].partes[0].grupos) {
                        let grupos = contenido.inicio[0].partes[0].grupos;
                        let slider = [];
                        for (var keyGrupo in grupos) {
                            let grupo = grupos[keyGrupo];
                            for (var keyValor in grupo) {
                                let valor = grupo[keyValor];
                                slider.push(valor[0]);
                                imagenes.push(CONFIG.URL_BASE + valor[0].valor);
                            }
                        }
                        setSliderPortada(slider);
                    }
                }
                if (contenido.inicio[0] && contenido.inicio[0].partes && contenido.inicio[0].partes.length > 0) {
                    if (contenido.inicio[0].partes[1] && contenido.inicio[0].partes[1].grupos) {
                        let grupos = contenido.inicio[0].partes[1].grupos;
                        let slider = [];
                        for (let keyGrupo in grupos) {
                            let grupo = grupos[keyGrupo];
                            for (let keyValor in grupo) {
                                let valor = grupo[keyValor];
                                slider.push(valor[0]);
                                imagenes.push(CONFIG.URL_BASE + valor[0].valor);
                            }
                        }
                        setSliderCategoria(slider);
                    }
                }
                cargaImagenes(imagenes);
            }
			if (contenido?.contacto.length && contenido.contacto[0].partes[2]?.grupos.whatsapp.WhatsApp[0].valor) {
				setNumeroWhatsApp(Helper.extraerTel(contenido.contacto[0].partes[2]?.grupos.whatsapp.WhatsApp[0].valor))
			}
        }
    }, [contenido]);
    const getContenidoSeccion = () => {
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        var options = {
            url: Api.route('p/seccion/inicio'),
            method: 'GET'
        };
        Api.requestAxios(options, response => {
            Helper.log('getContenidoSeccion response', response);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            if (response.data.error) {
                enqueueSnackbar(response.data.msg, { variant: 'error' });
            } else {
                if (response.data.datos) {
                    setContenido(response.data.datos);
                }
            }
        }, error => {
            Helper.log('error getContenidoSeccion', error);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            enqueueSnackbar('Error al traer datos de Inicio', { variant: 'error' });
        });
    }
    const cargaImagenes = (imagenes) => {
        var cantImgs = imagenes.length;
        var cargadas = 0;
        function loadImgs() {
            cargadas = cargadas + 1;
            if (cargadas === cantImgs) {
                setImagenesListas(true);
            }
        };
        if (cantImgs > 0) {
            for (let i in imagenes) {
                let image = new Image();
                image.src = imagenes[i];
                image.onload = function () {
                    loadImgs();
                };
            }
        } else {
            setImagenesListas(true);
        }
    }
    const armarCategorias = (jquias) => {
        let cats = [];
        //nivel 1
        for (let i in jquias) {
            jquias[i].subJerarquias = [];
            if (!jquias[i].jerarquiaPapaId) {
                cats.push(jquias[i]);
            }
        }
        //nivel 2
        for (let i in cats) {
            for (let x in jquias) {
                if (jquias[x].jerarquiaPapaId === cats[i].id) {
                    cats[i].subJerarquias.push(jquias[x]);
                }
            }
        }
        //nivel 3
        for (let i in cats) {
            for (let x in cats[i].subJerarquias) {
                for (let y in jquias) {
                    if (jquias[y].jerarquiaPapaId === cats[i].subJerarquias[x].id) {
                        cats[i].subJerarquias[x].subJerarquias.push(jquias[y]);
                    }
                }
            }
        }
        setCategorias1(cats);
    }
    const getConfig = () => {
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        var options = {
            url: Api.route('p/configuraciones'),
            method: 'GET'
        };
        Api.requestAxios(options, response => {
            Helper.log('getConfiguraciones response', response);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            if (response.data.error) {
                enqueueSnackbar(response.data.msg, { variant: 'error' });
            } else {
                // setConfiguraciones(response.data.datos);
                if (response.data && response.data.datos && response.data.datos.jerarquias) {
                    armarCategorias([...response.data.datos.jerarquias]);
					setProductosDestacados(response.data.datos.destacados.items)
                }
            }
        }, error => {
            Helper.log('error getConfiguraciones', error);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            enqueueSnackbar('Error al traer datos de configuración', { variant: 'error' });
        });
    }
    
    return (
        <div className="content-min-height">
            { sliderPortada && imagenesListas ? <CarouselProvider
                naturalSlideWidth={100}
                totalSlides={sliderPortada.length}
                visibleSlides={1}
                infinite={true}
                interval={5000}
                isPlaying={true}>
                <div style={{ width: '100%', height: (windowSize.width * (30 / 100) + 'px'), position: 'relative' }}>
                    <Slider style={{ height: '100%' }}>
                        {sliderPortada.map((slde, index) => <Slide key={slde.id} index={index} style={{ padding: 4 }}>
                            <div style={{ backgroundColor: '#ccc', width: '100%' }}>
                                <img style={{ width: '100%' }} src={CONFIG.URL_BASE + slde.valor} alt="productos"/>
                            </div>
                        </Slide>)}
                    </Slider>
                    <div className="w3-center" style={{ position: 'absolute', bottom: '20px', width: '100%' }}>
                        <DotGroup />
                    </div>
                </div>
            </CarouselProvider> : null}<br /><br />
			{productosDestacados.length > 0 &&
				<div className="w3-block">
					<div className="w3-content">
						<div className="w3-block w3-padding">
							<h2 className="w3-text-gray"><FontAwesomeIcon icon={faSquare} size="1x" className="w3-color-theme" /> Productos destacados</h2>
						</div>
						<GridResponsiveProductos items={productosDestacados} />
					</div>
				</div>
			}
            { sliderCategoria && imagenesListas ? <CarouselProvider
                naturalSlideWidth={100}
                totalSlides={sliderCategoria.length}
                visibleSlides={2}
                infinite={true}
                interval={5000}
                isPlaying={true}>
                <div style={{ width: '100%', height: (windowSize.width * (20 / 100) + 'px'), position: 'relative' }}>
                    <Slider style={{ height: '100%' }}>
                        {sliderCategoria.map((slde, index) => <Slide key={slde.id} index={index} style={{ height: 'auto', padding: 4 }}>
                            <div style={{ backgroundColor: '#fff', width: '100%' }}>
                                <img style={{ width: '100%' }} src={CONFIG.URL_BASE + slde.valor} alt="categorias" />
                            </div>
                        </Slide>)}
                    </Slider>
                    <ButtonBack className="carousel-back-btn"><FontAwesomeIcon icon={faChevronLeft} /></ButtonBack>
                    <ButtonNext className="carousel-next-btn"><FontAwesomeIcon icon={faChevronRight} /></ButtonNext>
                </div>
            </CarouselProvider> : null}
            <br />
            <br />
            <div className="w3-block">
                <div className="w3-content">
                    <div className="w3-row">
                        <div className="w3-col l4 w3-padding-small bs-bbox">
                            <div>
                                <div className="w3-row info-item">
                                    <div className="w3-col w3-text-gray w3-right-align bs-bbox" style={{ width: '40px', padding: '12px 12px 12px 0px' }}>
                                        <FontAwesomeIcon icon={faHandHoldingUsd} size="2x" />
                                    </div>
                                    <div className="w3-rest w3-padding-small bs-bbox">
                                        <span className="w3-color-theme xbold">COBRO CONTRA-ENTREGA</span><br />
                                        <span className="w3-text-gray w3-small">PAGÁS CUANDO RECIBÍS LOS PRODUCTOS</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w3-col l4 w3-padding-small bs-bbox">
                            <div>
                                <div className="w3-row info-item">
                                    <div className="w3-col w3-text-gray w3-right-align bs-bbox" style={{ width: '40px', padding: '12px 12px 12px 0px' }}>
                                        <FontAwesomeIcon icon={faTruck} size="2x" />
                                    </div>
                                    <div className="w3-rest w3-padding-small bs-bbox">
                                        <div>
                                            <span className="w3-color-theme xbold">ENVÍOS A TODO EL PAÍS</span><br />
                                            <span className="w3-text-gray w3-small">COMPRA MÍNIMA DE GS 500.000</span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
						{numeroWhatsApp &&
                        <div className="w3-col l4 w3-padding-small bs-bbox">
                            <a href={`tel:${numeroWhatsApp}`} style={{textDecoration: 'none'}}>
                                <div className="w3-row info-item">
                                    <div className="w3-col w3-text-gray w3-right-align bs-bbox" style={{ width: '40px', padding: '12px 12px 12px 0px' }}>
                                        <FontAwesomeIcon icon={faMobileAlt} size="2x" />
                                    </div>
                                    <div className="w3-rest w3-padding-small bs-bbox">
                                        <span className="w3-color-theme xbold">{Helper.agregarEspaciosTel(numeroWhatsApp)}</span><br />
                                        <span className="w3-text-gray w3-small">ATENCIÓN AL CLIENTE</span>
                                    </div>
                                </div>
                            </a>
                        </div>}
                    </div>
                </div>
            </div>
            <br />
            <div className="w3-block">
                <div className="w3-content">
                    <div className="w3-block w3-padding">
                        <h2 className="w3-text-gray"><FontAwesomeIcon icon={faSquare} size="1x" className="w3-color-theme" /> Categorías</h2>
                    </div>
                    <GridResponsiveCategorias items={categorias1} />
                </div>
            </div>
            <br />
            <br />
			<SupportBubble number={numeroWhatsApp} />
        </div>
    );
}
export default withRouter(Inicio);